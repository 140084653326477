.button {
  font-size: 0.875rem;
  line-height: 1.2;
  letter-spacing: 1.8px;
  padding: 12px 10px;
  text-transform: capitalize;
  text-decoration: none;
  border-radius: 5px;
  border: none;
  box-shadow: 0 3px 4px var(--black);
  background: var(--white);
  color: var(--black);
  display: inline-block;
  cursor: pointer;
}

.button:hover,
.button:active {
  color: var(--white);
  background: var(--purple);
}

.button--inverse {
  background: var(--black);
  color: var(--white);
}

.button--inverse:hover,
.button--inverse:active {
  background: var(--purple);
}

.button:disabled,
.button:hover:disabled,
.button:active:disabled {
  background: #ccc;
  color: #979797;
  border-color: #ccc;
  box-shadow: none;
  cursor: not-allowed;
}

.button.button--small {
  font-size: 1rem;
  padding: 5px 10px;
}

/* Smartphones, Android phones, landscape iphone 480px = 30em */
@media (min-width: 30em) {
  .button {
    font-size: 1.125rem;
    padding: 16px 32px;
  }
}

/* Tablet, landscape ipad, low-res laptops and desktops 801px = 50.063em */
@media (min-width: 50.063em) {
  .button {
    padding: 20px 36px;
  }
}
