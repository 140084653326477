.main-header__top-section {
  padding: 17px 0 12px;
}

.main-header__top-section .container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.main-navigation__menu-btn {
  width: 2.5rem;
  height: 2.5rem;
  background: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-right: auto;
  cursor: pointer;
  order: 1;
}

.main-navigation__menu-btn span {
  display: block;
  height: 4px;
  background: var(--black);
  border-radius: 10px;
  transition: width 0.3s ease-out;
}

.main-navigation__menu-btn span:nth-of-type(1) {
  width: 50%;
}

.main-navigation__menu-btn span:nth-of-type(2) {
  width: 100%;
}

.main-navigation__menu-btn span:nth-of-type(3) {
  width: 75%;
}

.main-navigation__menu-btn:hover span {
  width: 100%;
}

.main-navigation__primary-nav {
  display: none;
}

.side-drawer__company-logo {
  margin: 20px 0;
}

.main-navigation__drawer-nav {
  font-size: 0.875rem;
}

.main-navigation__drawer-nav-links {
  list-style: none;
  text-transform: uppercase;
  text-align: center;
}

.main-navigation__drawer-nav-links li {
  flex: 1;
  margin-bottom: 10px;
}

.main-navigation__nav-icon,
.main-navigation__nav-title {
  display: block;
}

.main-navigation__drawer-nav-links a,
.side-drawer__secondary-nav-links a {
  display: block;
}

.main-navigation__drawer-nav-links a {
  text-decoration: none;
  color: var(--white);
  background-color: var(--black);
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(5, 5, 5, 0.4);
  padding: 10px;
}

.main-navigation__drawer-nav-links a:hover,
.main-navigation__drawer-nav-links a:active,
.main-navigation__drawer-nav-links a.active {
  color: var(--purple);
}

.side-drawer__product-categories {
  margin: 20px 0;
}

.secondary-nav-links__title {
  font: 1.5rem/28px "Bodoni Moda", serif;
  display: block;
  text-align: center;
  margin-bottom: 20px;
}

.side-drawer__secondary-nav-links {
  font-size: 0.875rem;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  text-align: center;
  height: auto;
}

.side-drawer__secondary-nav-links li {
  margin: 0;
}

.side-drawer__secondary-nav-links a {
  padding: 10px;
}

.side-drawer__secondary-nav-links a:hover::after,
.side-drawer__secondary-nav-links a:active::after,
.side-drawer__secondary-nav-links a.active::after {
  left: 30%;
  right: 30%;
}

.side-drawer__social-links {
  justify-content: center;
}

.side-drawer__social-links li {
  margin: 0 10px;
}

.side-drawer__social-links a {
  color: var(--black);
}

.main-navigation__date-and-rate {
  margin: 0 auto 0 0;
  order: 3;
}

.main-navigation__company-logo {
  text-align: center;
  flex-basis: auto;
  margin: 20px 0 40px;
  order: 2;
}

.main-navigation__secondary-nav {
  border-width: 1px 0 1px 0;
  border-color: #050505;
  border-color: rgba(5, 5, 5, 0.1);
  border-style: solid;
  display: none;
}

/* Smartphones, Android phones, landscape iphone 480px = 30em */
@media (min-width: 30em) {
  .main-navigation__drawer-nav-links {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin: 0 -5px;
  }

  .main-navigation__drawer-nav-links li {
    margin: 0 5px;
  }

  .main-navigation__drawer-nav-links a {
    padding: 20px 5px;
  }
}

/* Big landscape tablets, laptops and desktops 1025px = 64.0625em */
@media (min-width: 64.0625em) {
  .main-navigation__menu-btn {
    display: none;
    order: unset;
  }

  .main-navigation__primary-nav {
    display: block;
  }

  .main-navigation__secondary-nav {
    display: block;
  }

  .main-navigation__date-and-rate {
    order: unset;
  }

  .main-navigation__company-logo {
    order: unset;
    margin: 20px 0 40px;
  }
}

/* Hi-res laptops and desktops 1281px = 80.0625em */
@media (min-width: 80.0625em) {
  .main-header__top-section .container {
    flex-direction: row;
  }

  .main-navigation__company-logo {
    margin: 0;
  }

  .main-navigation__date-and-rate {
    flex-basis: 24rem;
    margin: 0;
  }

  .main-navigation__primary-nav {
    flex-basis: 24rem;
    margin-top: -6px;
  }
}
