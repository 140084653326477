.modal {
  z-index: 99999;
  position: fixed;
  top: calc((110vh / 1080) * 100);
  left: 10%;
  width: 100%;
  background: var(--white);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 20px;
}

.modal__image-wrapper > img {
  height: 100%;
  object-fit: cover;
  border-radius: 20px 0 0 20px;
}

.modal__text-wrapper {
  position: relative;
  padding: 15px;
  max-height: 80vh;
  overflow: auto;
}

.modal__title {
  font: 1.5rem/1.52 "Bodoni Moda", serif;
  font-weight: 700;
  letter-spacing: 5px;
  text-transform: capitalize;
  margin: 0 0 15px;
}

.modal__description {
  margin: 0 0 15px;
}

.modal__close-btn {
  background-color: transparent;
  color: var(--black);
  border: none;
  outline: none;
  cursor: pointer;
  position: absolute;
  top: 2%;
  right: 2%;
}

.modal__close-btn:hover,
.modal__close-btn:active {
  color: var(--purple);
}

.modal__form--invalid .input,
.modal__form--invalid .textarea {
  border-color: red;
  background: #ffd1d1;
}

.modal__close-btn .MuiSvgIcon-root {
  width: 0.625em;
  height: 0.625em;
}

.modal__size-small {
  left: calc(50% - 40vw);
  width: 80vw;
  height: auto;
  text-align: center;
}

.modal__size-small .modal__description {
  margin: 0 auto 20px;
}

.modal__size-big {
  width: 85vw;
  top: calc(50% - 40vh);
  left: calc(50% - 42.5vw);
}

.modal-enter {
  transform: translateY(-10rem);
  opacity: 0;
}

.modal-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: all 200ms;
}

.modal-exit {
  transform: translateY(0);
  opacity: 1;
}

.modal-exit-active {
  transform: translateY(-10rem);
  opacity: 0;
  transition: all 200ms;
}

/* Smartphones, Android phones, landscape iphone 480px = 30em */
@media (min-width: 30em) {
  .modal__title {
    font-size: 2rem;
    margin: 0 0 20px;
  }

  .modal__close-btn .MuiSvgIcon-root {
    width: 1em;
    height: 1em;
  }

  .modal__text-wrapper {
    padding: 20px;
  }

  .modal__close-btn {
    top: 1%;
    right: 1%;
  }

  .modal__description {
    margin: 0 0 20px;
  }
}

/* Big landscape tablets, laptops and desktops 1025px = 64.0625em */
@media (min-width: 64.0625em) {
  .modal__description {
    width: calc(400rem / 16);
  }

  .modal__size-small {
    left: calc(50% - 25vw);
    width: 50vw;
    height: auto;
    text-align: center;
  }
}

/* Hi-res laptops and desktops 1281px = 80.0625em */
@media (min-width: 80.0625em) {
  .modal__text-wrapper {
    position: relative;
    padding: 40px 50px;
  }

  .modal__title {
    font-size: 2.625rem;
  }

  .modal__description {
    margin: 0 0 40px;
  }
}
