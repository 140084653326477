.primary-nav-links {
  list-style: none;
  padding: 0;
  height: 100%;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  --gap: 30px;
  margin-right: calc(-1 * var(--gap));
}

.primary-nav-links li {
  margin: 0 var(--gap) 0 0;
}

.primary-nav-links a {
  display: inline-block;
  text-decoration: none;
  color: var(--black);
  padding-bottom: 4px;
  position: relative;
}

.primary-nav-links a::before {
  content: attr(title);
  display: block;
  font-weight: 700;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}

.primary-nav-links a:hover,
.primary-nav-links a:active,
.primary-nav-links a.active {
  font-weight: 700;
}

.primary-nav-links a:hover::after,
.primary-nav-links a:active::after,
.primary-nav-links a.active::after {
  content: "";
  border-top: 1px solid var(--black);
  border-bottom: 1px solid var(--black);
  border-radius: 1px;
  position: absolute;
  bottom: 2px;
  left: 0;
  right: 0;
  margin: 0 -2px 0;
}

/* Tablet, landscape ipad, low-res laptops and desktops 801px = 50.063em */
@media (min-width: 50.063em) {
  .primary-nav-links {
    --gap: 50px;
  }
}
