.social-links {
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: -10px;
}

.social-links__icon {
  margin-left: 10px;
}

.social-links__icon > a {
  display: block;
  color: var(--white);
}

.social-links__icon > a:hover {
  color: var(--purple);
}
