.footer-elements__copyright {
  letter-spacing: 1.6px;
  padding: 30px 0;
  color: var(--white);
  text-align: center;
}

.footer-elements__designer-link {
  text-decoration: none;
  color: var(--white);
}

.footer-elements__designer-link:hover {
  color: var(--purple);
}

/* Smartphones, Android phones, landscape iphone 480px = 30em */
@media (min-width: 30em) {
  .footer-elements__copyright {
    padding: 40px 0;
  }
}

/* Hi-res laptops and desktops 1281px = 80.0625em */
@media (min-width: 80.0625em) {
  .footer-elements__copyright {
    padding: 50px 0;
  }
}
