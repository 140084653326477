.current-date {
  font-size: 0.875rem;
  opacity: 0.8;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  display: block;
}

/* Hi-res laptops and desktops 1281px = 80.0625em */
@media (min-width: 80.0625em) {
  .current-date {
    font-size: 1rem;
    padding-bottom: 6px;
  }
}
