.side-drawer {
  position: absolute;
  width: 75%;
  height: 100vh;
  overflow: auto;
  top: 0;
  left: 0;
  z-index: 999999;
  background: var(--white);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Smartphones, Android phones, landscape iphone 480px = 30em */
@media (min-width: 30em) {
  .side-drawer {
    padding: 20px;
  }
}
