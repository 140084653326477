.admin-navigation__side-drawer {
  justify-content: flex-start;
}

.main-header__admin-nav-top-section {
  padding: 17px 0 12px;
}

.admin-navigation__button-wrapper {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.admin-navigation__button-wrapper .admin-nav-links__logout-btn {
  order: 2;
}

.side-drawer__admin-nav-links {
  flex-direction: column;
  align-items: stretch;
  text-align: center;
}

.side-drawer__admin-nav-links li {
  margin: 0;
}

.side-drawer__admin-nav-links li:last-child {
  margin-top: 10px;
}

.side-drawer__admin-nav-links a {
  padding: 10px;
}

.admin-navigation__company-logo {
  text-align: center;
  margin: 20px 0 40px;
  /* order: 2; */
}

.admin-navigation__links {
  display: none;
  /* order: 3; */
}

/* Portrait tablets, portrait ipad, e-readers 600px = 37.5em */
@media (min-width: 37.5em) {
  .admin-navigation__links {
    display: block;
  }

  .admin-navigation__button-wrapper{
    display: none;
  }
}
