.admin-nav-links {
  list-style: none;
  padding: 0;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.admin-nav-links li {
  margin: 0 25px;
}

.admin-nav-links a {
  display: inline-block;
  text-decoration: none;
  color: var(--black);
  padding-bottom: 4px;
  position: relative;
}

.admin-nav-links a::before {
  content: attr(title);
  display: block;
  font-weight: 700;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}

.admin-nav-links a:hover,
.admin-nav-links a:active,
.admin-nav-links a.active {
  font-weight: 700;
}

.admin-nav-links a:hover::after,
.admin-nav-links a:active::after,
.admin-nav-links a.active::after {
  content: "";
  border-top: 1px solid var(--black);
  border-bottom: 1px solid var(--black);
  border-radius: 1px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.admin-nav-links__logout-btn {
  font: 1rem/26px "Montserrat", sans-serif;
  padding: 6px;
  text-transform: uppercase;
  cursor: pointer;
}
