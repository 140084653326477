.form-control p {
  margin: 0 0 15px;
}

.form-control--invalid {
  color: var(--light-purple);
}

.form-control--invalid .input,
.form-control--invalid .textarea {
  border-color: var(--light-purple);
}

.form-control label {
  font-weight: 700;
  display: block;
  margin-bottom: 10px;
}

.input,
.textarea {
  font: 0.875rem/1.19 "Montserrat", sans-serif;
  letter-spacing: 2px;
  display: block;
  background-color: transparent;
  border-width: 0 0 1px;
  border-style: solid;
  border-color: var(--black);
  width: 100%;
}

.textarea {
  resize: none;
}

.input:focus,
.textarea:focus {
  border-color: var(--purple);
  outline: none;
}

/* Smartphones, Android phones, landscape iphone 480px = 30em */
@media (min-width: 30em) {
  .form-control p {
    margin: 0 0 20px;
  }

  .input,
  .textarea {
    font-size: 1rem;
  }
}

/* Tablet, landscape ipad, low-res laptops and desktops 801px = 50.063em */
@media (min-width: 50.063em) {
  .input,
  .textarea {
    width: calc(330rem / 16);
  }
}
