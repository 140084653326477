:root {
  --black: #050505;
  --ligth-black: #221f3b;
  --purple: #6f4a8e;
  --light-purple: #b030b0;
  --white: #ebebeb;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
picture,
main {
  display: block;
}

body {
  font: 0.875rem/26px "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--white);
  background-image: url("https://www.transparenttextures.com/patterns/cubes.png");
  color: var(--black);
  min-width: 20rem;
  margin: 0;
}

.container {
  max-width: 120rem;
  padding: 0 15px;
  margin: 0 auto;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
}

.center {
  text-align: center;
}

.slide-in-left-enter {
  transform: translateX(-100%);
}

.slide-in-left-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: all 200ms;
}

.slide-in-left-exit {
  transform: translateX(0%);
  opacity: 1;
}

.slide-in-left-exit-active {
  transform: translateX(-100%);
  opacity: 0;
  transition: all 200ms;
}

/* Smartphones, Android phones, landscape iphone 480px = 30em */
@media (min-width: 30em) {
  .container {
    padding: 0 20px;
  }
}

/* Hi-res laptops and desktops 1281px = 80.0625em */
@media (min-width: 80.0625em) {
  body {
    font-size: 1rem;
  }

  .container {
    padding: 0 50px;
  }
}
