.secondary-nav-links {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.secondary-nav-links li {
  margin: 16px 15px 12px;
}

.secondary-nav-links a {
  display: inline-block;
  text-decoration: none;
  color: var(--black);
  padding-bottom: 4px;
  position: relative;
}

.secondary-nav-links a::before {
  content: attr(title);
  display: block;
  font-weight: 700;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}

.secondary-nav-links a:hover,
.secondary-nav-links a:active,
.secondary-nav-links a.active {
  font-weight: 700;
}

.secondary-nav-links a:hover::after,
.secondary-nav-links a:active::after,
.secondary-nav-links a.active::after {
  content: "";
  border-top: 1px solid var(--black);
  border-bottom: 1px solid var(--black);
  border-radius: 1px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

/* Big landscape tablets, laptops and desktops 1025px = 64.0625em */
@media (min-width: 64.0625em) {
  .secondary-nav-links li {
    margin: 16px 1.2% 12px;
  }
}

/* Hi-res laptops and desktops 1281px = 80.0625em */
@media (min-width: 80.0625em) {
  .secondary-nav-links li {
    margin: 16px 1.4% 12px;
  }
}
