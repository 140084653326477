.footer-nav-links__heading {
  font-family: "Bodoni Moda", serif;
  font-weight: 700;
  letter-spacing: 1.8px;
  text-transform: uppercase;
  color: var(--white);
  display: block;
  margin-bottom: 16px;
}

.footer-nav-links__items {
  letter-spacing: 1.8px;
  list-style: none;
  margin: 0 0 15px;
}

.footer-nav-links__items > li {
  margin-bottom: 3px;
}

.footer-nav-links__items > li a {
  text-decoration: none;
  text-transform: uppercase;
  color: var(--white);
  opacity: 0.8;
}

.footer-nav-links__items > li a:hover {
  color: var(--purple);
}

/* Smartphones, Android phones, landscape iphone 480px = 30em */
@media (min-width: 30em) {
  .footer-nav-links__items {
    margin: 0;
  }
}
