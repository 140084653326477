.footer-links {
  padding: 30px 0;
  border-bottom: 1px solid rgba(235, 235, 235, 0.2);
}

.footer-links > .container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

.footer-links__logo {
  flex-basis: 100%;
  margin: 0 0 30px;
}

/* Smartphones, Android phones, landscape iphone 480px = 30em */
@media (min-width: 30em) {
  .footer-links {
    padding: 40px 0;
  }

  .footer-links > .container {
    flex-direction: row;
  }
}

/* Tablet, landscape ipad, low-res laptops and desktops 801px = 50.063em */
@media (min-width: 50.063em) {
  .footer-links__logo {
    flex-basis: initial;
    margin: 0;
  }
}

/* Hi-res laptops and desktops 1281px = 80.0625em */
@media (min-width: 80.0625em) {
  .footer-links {
    padding: 50px 0;
  }
}
