.newsletter {
  text-align: center;
  padding: 36px 0;
  border-bottom: 1px solid rgba(235, 235, 235, 0.2);
}

.newsletter__title {
  font: 1.625rem/1.2 "Bodoni Moda", serif;
  text-transform: capitalize;
  color: var(--white);
  margin: 0 0 31px;
}

.newsletter__description {
  letter-spacing: 1.2px;
  color: var(--white);
  opacity: 0.6;
  margin: 0 auto 28px;
}

.newsletter__email {
  text-align: center;
  opacity: 0.6;
  border-color: var(--white);
  color: var(--white);
  padding-bottom: 11px;
  margin: 0 auto 30px;
}

.newsletter__email:focus {
  border-color: var(--purple);
}

/* Smartphones, Android phones, landscape iphone 480px = 30em */
@media (min-width: 30em) {
  .newsletter {
    padding: 46px 0;
  }

  .newsletter__title {
    margin: 0 0 41px;
  }

  .newsletter__description {
    width: 50%;
  }
}

/* Portrait tablets, portrait ipad, e-readers 600px = 37.5em */
@media (min-width: 37.5em) {
  .newsletter__title {
    font-size: 2rem;
  }
}

/* Tablet, landscape ipad, low-res laptops and desktops 801px = 50.063em */
@media (min-width: 50.063em) {
}

/* Big landscape tablets, laptops and desktops 1025px = 64.0625em */
@media (min-width: 64.0625em) {
  .newsletter__description {
    width: 30%;
  }
}

/* Hi-res laptops and desktops 1281px = 80.0625em */
@media (min-width: 80.0625em) {
  .newsletter__description {
    width: 20%;
  }
  .newsletter {
    padding: 56px 0;
  }
}
