.gold-silver-rate {
  font-size: 0.875rem;
  letter-spacing: 1.2px;
  opacity: 0.8;
  display: block;
  border-spacing: 0;
  border-color: transparent;
}

.gold-silver-rate__title {
  text-transform: uppercase;
  text-align: left;
  padding: 0 7px 0 0;
  opacity: 1;
}

/* Hi-res laptops and desktops 1281px = 80.0625em */
@media (min-width: 80.0625em) {
  .gold-silver-rate {
    font-size: 1.125rem;
  }

  .gold-silver-rate__title {
    padding: 0 7px 6px 0;
  }

  .gold-silver-rate td {
    padding-bottom: 6px;
  }
}
